<template>
	<div class="info-detail-page">
		<van-row class="title">
			<van-col span="24" v-html="title"></van-col>
		</van-row>
		<van-row class="date">
			<van-col span="24" v-html="date"></van-col>
		</van-row>
		<van-row class="desc">
			<van-col span="24" v-html="desc"></van-col>
		</van-row>		
	</div>
</template>

<script>
	export default {
		name: 'risk_info',
		data() {
			return {
				id: 0,
				title: "",
				desc: "",
				date: "",
			}
		},
		mounted() {
			this.id = this.$route.query.id
		
			this.loadData()
		},
		activated() {
			//	如果第二次进入修改的数据不同，则重新获取数据
			if (this.$route.query.id != this.id) {
				this.id = this.$route.query.id
		
				this.loadData()
			}
		},
		methods: {
			loadData() {
				this.$axios({
					method: 'post',
					url: 'fund_risk/info', 
					data: {
						id: this.id
					}
				}).then ((res) => {
					if (res.success) {
						
						this.title = res.data.title
						this.desc = res.data.desc		
						this.date = res.data.date				
					}
				})
			},
		},
	}
</script>